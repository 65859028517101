export const PRESET_FORM_VALUES = Object.freeze({
  name: '',
  balance: 0,
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  assetAccount: '',
  interestIncome: '',
  financialInstitutionName: ''
})

export function data() {
  return {
    formComplete: false,
    formData: {
      ...PRESET_FORM_VALUES
    },
    interestIncomeAccounts: [],
    assetAccounts: [],
    loading: true
  }
}
