import { PRESET_FORM_VALUES } from './data'

import { upsertUnlinkedBankAccountForClient } from '@/services/BankAccounts/BankAccountUnlinked/UpsertBankAccountForClient'
import { notifyMessage, notifyError } from '@/services/notify'
import { ValidationError } from '@/services/errors'
import { addFinInstituion } from '@/services/CheckbookReconciliation/FinancialInstitution/Post'
import { getGLAccountsList } from '@/services/GeneralLedger/Account/List'

import _get from 'lodash/get'
import moment from 'moment'
import { mapActions } from 'vuex'

export const methods = {
  ...mapActions('bankaccount', ['loadBankAccountTypesList']),
  ...mapActions('hoa', ['loadAssetGLAccountsList', 'loadInterestIncomeGLAccountsList']),

  async refresh() {},

  async procureGLAccountsList() {
    /*
		  for getting the "Income" & "Asset" accounts
		*/
    const { successful, message, parsedAccounts } = await getGLAccountsList({
      parseTypes: true,
      params: {
        hoaID: this.hoaId
      }
    })
    if (!successful) {
      notifyError(message)
      return {
        parsedAccounts: {}
      }
    }

    return {
      parsedAccounts
    }
  },

  async resetForm() {
    this.loading = true
    this.formData = { ...PRESET_FORM_VALUES }

    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    const { parsedAccounts } = await this.procureGLAccountsList()
    this.assetAccounts = _get(parsedAccounts, ['Asset'], [])
    this.interestIncomeAccounts = _get(parsedAccounts, ['Income'], [])

    await this.loadBankAccountTypesList()

    this.loading = false
  },

  stepChange() {},

  getFormPayload({ financialInstitutionID }) {
    const now = moment()

    const payload = {
      hoaID: this.hoaId,
      name: this.formData.name,
      accountNumber: this.formData.accountNumber,
      routingNumber: this.formData.routingNumber,
      bankAccountTypeID: this.formData.accountType,
      initialBalance: parseFloat(this.formData.balance || 0).toFixed(2),
      financialInstitutionID,
      bankGLAccountID: this.formData.assetAccount,
      interestIncomeGLAccountID: this.formData.interestIncome,
      createdDate: now.toDate(),
      modifiedDate: now.toDate()
    }

    return payload
  },

  async onFormSubmit() {
    this.loading = true

    let financialInstitutionID = null

    await addFinInstituion({
      json: {
        hoaID: this.hoaId,
        name: this.formData.financialInstitutionName,
        providerInstitutionID: null,
        setAsUnlinked: true
      }
    }).then(({ successful, message, data }) => {
      if (!successful) {
        throw new Error(message)
      }

      financialInstitutionID = data.financialInstitutionID
    })

    try {
      const { successful, message, result } = await upsertUnlinkedBankAccountForClient({
        json: this.getFormPayload({
          financialInstitutionID
        })
      })
      if (!successful) {
        notifyError(message)
        return
      }

      if (result.bankAccountID >= 0) {
        this.formComplete = true
        this.resetForm()
        this.$router.push('/bankAccounts')
        notifyMessage(`${result.name} was successfully added.`)
      } else {
        notifyError('There was a problem creating your Linked Bank Account.')
      }
    } catch (e) {
      // If this is a validation error, get the details for each field
      if (e instanceof ValidationError) {
        this.$refs.form.setErrors(e.fields)
      }

      notifyError(e)
    }

    this.loading = false
  }
}
