<template>
  <PageContent :title="$t('createUnlinked.title')">
    <div class="box is-8">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <section>
              <b-steps label-position="bottom" size="medium" @input="stepChange">
                <b-step-item label="Institution" icon="account">
                  <fieldset>
                    <div class="column is-8">
                      <valid-input
                        v-model="formData.financialInstitutionName"
                        vid="financialInstitutionName"
                        name="Financial Institution Name"
                        label="Financial Institution Name"
                        placeholder="Financial Institution Name"
                        type="text"
                        rules="required"
                      />
                    </div>
                    <div class="column is-8">
                      <valid-input
                        name="Account Name"
                        label="Account Name"
                        type="text"
                        vid="name"
                        placeholder="Name"
                        rules="required"
                        v-model="formData.name"
                      />
                    </div>
                    <div class="column is-8">
                      <valid-input
                        name="Balance"
                        label="Balance"
                        type="text"
                        vid="balance"
                        placeholder="Balance"
                        rules="required"
                        v-model="formData.balance"
                        onkeypress="return (event.charCode >= 48 || event.charCode == 46) && event.charCode <= 57"
                      />
                    </div>
                    <div class="column is-8">
                      <valid-select
                        v-model="formData.accountType"
                        placeholder="Account Type"
                        label="Account Type"
                        vid="accountType"
                        rules="required"
                      >
                        <option
                          v-for="option in bankAccountTypes"
                          :value="option.value"
                          :key="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                  </fieldset>
                </b-step-item>
                <b-step-item label="Account" icon="account-plus" disabled>
                  <fieldset>
                    <div class="column is-8">
                      <valid-select
                        v-model="formData.assetAccount"
                        placeholder="Asset Account"
                        label="Asset Account"
                        vid="assetAccount"
                        rules="required"
                      >
                        <option
                          v-for="option in assetAccounts"
                          :value="option.value"
                          :key="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                    <div class="column is-8">
                      <valid-select
                        v-model="formData.interestIncome"
                        placeholder="Interest Income"
                        label="Interest Income"
                        vid="interestIncome"
                        rules="required"
                      >
                        <option
                          v-for="(option, index) in interestIncomeAccounts"
                          :key="index"
                          :value="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                    <div class="column is-8">
                      <valid-input
                        name="Account Number"
                        label="Account Number"
                        maxlength="34"
                        type="text"
                        vid="accountNumber"
                        placeholder="Account Number"
                        rules="required|max:34|numeric"
                        v-model="formData.accountNumber"
                      />
                    </div>
                    <div class="column is-8">
                      <valid-input
                        name="Routing Number"
                        label="Routing Number"
                        maxlength="9"
                        type="text"
                        vid="routingNumber"
                        placeholder="Routing Number"
                        rules="required|max:9|length:9|numeric"
                        v-model="formData.routingNumber"
                      />
                    </div>
                  </fieldset>
                  <fieldset>
                    <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                      <div class="field">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </fieldset>
                  <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                    Submit
                  </button>
                </b-step-item>
              </b-steps>
            </section>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </PageContent>
</template>

<script>
//
//
import PageContent from '@/containers/PageContent'
//
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
//
//
import { ValidationObserver, ValidationProvider } from 'vee-validate'
//
//
//
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'
//
export default {
  components: {
    PageContent,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  computed,
  data,
  methods,

  mounted() {
    this.resetForm()
  },

  watch: {
    hoaId() {
      this.refresh()
    }
  },

  i18n: {
    messages: {
      en: { createUnlinked: { title: 'Add Unlinked Account' } }
    }
  }
}
</script>
